<template>
    <ClientOnly>
        <Tooltip
            :backdrop="backdrop"
            @show="updateShow"
            :placement="placement"
            ref="pickTooltip"
        >
            <template #default>
                <div class="button">
                    <FormButton
                        class="l-button--icon-only"
                        :class="{
                            'l-button--size-40': size40,
                        }"
                        v-if="!show"
                    >
                        <IconsBasket />
                    </FormButton>
                    <FormButton
                        class="l-button--icon-only l-button--secondary l-button--outlined button__close"
                        :class="{
                            'l-button--size-40': size40,
                        }"
                        v-else
                    >
                        <IconsClose />
                    </FormButton>
                </div>
            </template>
            <template #tooltip>
                <div class="content">
                    <template v-if="product?.children?.length > 0">
                        <div
                            class="equal-small-2 equal-small-2--bold-uppercase content__heading"
                        >
                            {{ t('product.picker.weight') }}
                        </div>
                        <FormRadioSelect
                            class="content__select"
                            :choices="choices"
                            v-model="selected"
                        >
                            <template #default="choice">
                                <FormRadioSelectItemCut :choice="choice.cut" />
                            </template>
                        </FormRadioSelect>
                    </template>
                    <div
                        class="equal-small-2 equal-small-2--bold-uppercase content__heading"
                    >
                        {{ t('product.picker.quantity') }}
                    </div>
                    <FormStepper
                        class="content__stepper"
                        :min="1"
                        @limit-reached="showLimitError"
                        :max="maxAvailable"
                        :disabled="maxReached"
                        v-model="quantity"
                    />
                    <MessageRemovable
                        v-if="error"
                        class="message--danger content__quantity-error"
                        @remove="error = null"
                    >
                        <template #default>
                            <div v-html="error"></div>
                        </template>
                        <template #icon>
                            <IconsClose />
                        </template>
                    </MessageRemovable>
                    <div class="content__price">
                        <div
                            class="scale-3 scale-3--bold-uppercase content__price"
                            :class="{
                                'content__price--offer': price?.action,
                            }"
                        >
                            {{ price?.currency }} {{ price?.incl_tax }}
                        </div>
                        <div
                            v-if="price?.action"
                            class="equal-base equal-base--tighter-uppercase content__price-invalid"
                        >
                            {{ price?.currency }}
                            {{
                                price?.action
                                    ? price?.original_incl_tax
                                    : price?.incl_tax
                            }}
                        </div>
                    </div>
                    <FormButton
                        class="l-button--auto-width content__button"
                        :label="t('product.picker.basket')"
                        @click="addToBasket"
                        :disabled="disabled"
                    >
                        <IconsBasket />
                    </FormButton>
                </div>
            </template>
        </Tooltip>
    </ClientOnly>
</template>

<script setup>
import { onMounted } from 'vue';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useTrackAddToCart } from '~/composables/gtm/useEcomTracking';
import { useDetailStore } from '~/stores/detail';
import { useBasketStore } from '~/stores/basket';
import { watch } from 'vue';

const { t } = useI18n();

const show = ref(false);
const error = ref(null);

const props = defineProps({
    product: {
        type: Object,
        required: true,
        validator: (value) => {
            return value?.price && value?.availability;
        },
    },
    placement: {
        type: String,
        default: 'top-start',
    },
    backdrop: {
        type: Boolean,
        default: true,
    },
    size40: {
        type: Boolean,
        default: false,
    },
    availabilities: {
        type: Object,
        required: true,
    },
    parent: {
        type: String,
        required: false,
        default: '',
    },
    detailPage: {
        type: Boolean,
        required: false,
    },
    parentTitle: {
        type: String,
        required: false,
    },
    parentType: {
        type: String,
        required: false,
    },
});
const detailStore = useDetailStore();
const basketStore = useBasketStore();
const quantity = props.detailPage ? toRef(detailStore, 'quantity') : ref(1);

const loading = ref(false);
const selected = props.detailPage ? toRef(detailStore, 'variant') : ref(null);
const pickTooltip = ref(null);

function updateShow(value) {
    show.value = value;
}

function showLimitError() {
    error.value = t('errors.unavailable');
}
function addToBasket() {
    loading.value = true;
    basketStore
        .addToBasket(
            selected.value,
            quantity.value,
            props.product,
            price.value,
            props.detailPage ? 'detail' : 'common',
        )
        .then((res) => {
            pickTooltip.value.hide();
            useTrackAddToCart(
                props.product,
                price.value,
                props.parentType,
                quantity.value,
                props.product.type,
                selected.value,
            );
            loading.value = false;
        })
        .catch(() => {
            error.value = t('basket.addError');
            basketStore.removeFakeLines();
        });
}

const availability = computed(() => {
    if (props.product.structure === 'standalone')
        return props.availabilities[0];
    return props.availabilities?.find((choice) => choice.id === selected.value);
});

const maxAvailable = computed(() => {
    return (
        availability.value?.num_available -
            basketStore.overallQuantityForProduct(selected.value) ||
        availability.value?.num_available ||
        999
    );
});

const maxReached = computed(() => {
    return (
        basketStore.overallQuantityForProduct(selected.value) >=
        availability.value?.num_available
    );
});

const disabled = computed(() => {
    return (
        loading.value ||
        (props.product?.children?.length && !selected.value) ||
        !availability.value?.is_available_to_buy ||
        maxReached.value
    );
});

const getPriceFromAvailability = (a) => {
    if (!a) return {};
    return {
        incl_tax: a.incl_tax,
        action: a.action,
        original_incl_tax: a.original_incl_tax,
        discount: a.discount,
        currency: a.currency,
        tax: a.tax,
        special: a.special,
        excl_tax: a.excl_tax,
    };
};

const price = computed(() => {
    return getPriceFromAvailability(availability.value);
});

const choices = computed(() => {
    let res = [];
    let cuts = props.product?.children;
    cuts?.forEach((c) => {
        const item = props.availabilities?.find((choice) => choice.id === c.id);
        if (item) {
            c.disabled = !item?.is_available_to_buy;
        }
        c.dataCy = 'product-picker-weight';
        res.push(c);
    });
    return res;
});

const getActiveChild = (product) => {
    if (product.structure === 'standalone') return product;
    return product?.children?.find((c) => !c.disabled);
};

watch(maxReached, (newVal) => {
    if (newVal && availability.value.num_available > 0) {
        error.value = t('errors.maxReached');
    } else {
        error.value = '';
    }
});

onMounted(() => {
    if (!selected.value && props.product.structure === 'parent') {
        const child = getActiveChild(props.product);
        if (child) {
            selected.value = child.id;
        }
    }
    if (!selected.value && props.product.structure === 'standalone') {
        selected.value = props.product.id;
    }
});
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';

.button {
    &__close {
        border-color: $color-dark-01 !important;
    }
    :deep(.l-button):hover,
    :deep(.l-button):focus,
    :deep(.l-button):active {
        background-color: $color-plain-grey-70;
    }
}

.content {
    // width: min(310px, 100% - 30px);
    width: 310px;
    padding: 30px;
    background-color: $color-dark-02;
    border-radius: 20px;

    &__heading {
        color: $color-disabled;
        margin-bottom: 10px;
    }

    &__select {
        margin-bottom: 35px;
    }

    &__stepper {
        margin-bottom: 35px;
    }

    &__quantity-error {
        margin-bottom: 35px;
    }

    &__price {
        &--offer {
            color: #c62424;
        }
    }

    &__price-invalid {
        color: #999999;
        text-decoration: line-through;
    }

    &__button {
        margin-top: 20px;
        &:hover,
        &:focus,
        &:active {
            background-color: $color-plain-grey-70;
        }
    }
}
</style>
