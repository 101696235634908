<template>
    <tippy
        :on-show="openTooltip"
        :on-hide="closeTooltip"
        :placement="placement"
        :appendTo="body"
        trigger="click"
        :interactive="true"
        :delay="0"
        :duration="0"
        ref="tippyRef"
    >
        <div :class="{ trigger: backdrop }">
            <slot name="default"></slot>
        </div>
        <template #content>
            <div class="content">
                <slot name="tooltip"></slot>
            </div>
        </template>
    </tippy>
    <div class="backdrop" v-if="props.backdrop & backdrop"></div>
</template>

<script setup>
import { onMounted } from 'vue';
import { ref } from 'vue';
import { Tippy } from 'vue-tippy';

const props = defineProps({
    placement: {
        type: String,
        default: 'top-end',
    },
    backdrop: {
        type: Boolean,
        default: false,
    },
});

const emit = defineEmits(['show']);
const backdrop = ref(false);
const tippyRef = ref(null);

function openTooltip() {
    backdrop.value = true;
    emit('show', true);
}

function closeTooltip() {
    backdrop.value = false;
    emit('show', false);
}

function hide() {
    tippyRef.value.hide();
}

const body = ref(null);
onMounted(() => {
    body.value = document.body;
});

defineExpose({
    hide,
});
</script>

<style lang="scss" scoped>
.trigger {
    position: relative;
    z-index: 20;
}

.content {
    z-index: 20;
}

.backdrop {
    z-index: 10;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000;
    opacity: 0.8;
}
</style>
